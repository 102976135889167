import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const ChatInterface = ({ onSendMessage, messages, isThinking, exampleText }) => {
  const [message, setMessage] = useState('');
  const [placeholder, setPlaceholder] = useState('Describe a feature or an issue...');

  useEffect(() => {
    if (message === '') {
      setPlaceholder('Describe a feature or an issue...');
    } else {
      setPlaceholder('');
    }
  }, [message]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim()) {
      onSendMessage(message.trim());
      setMessage('');
    }
  };

  const handleFocus = () => {
    if (message === '') {
      setMessage(exampleText);
    }
  };

  return (
    <div className="chat-interface">
      <div className="chat-history p-4 h-48 overflow-y-auto border-b border-gray-700">
        {messages.map((msg, index) => (
          <div key={index} className={`mb-2 ${msg.sender === 'user' ? 'text-right' : 'text-left'}`}>
            <span className={`inline-block p-2 rounded-lg ${
              msg.sender === 'user' 
                ? 'bg-blue-500 text-white' 
                : 'bg-gray-700 text-gray-200'
            }`}>
              {msg.text}
            </span>
          </div>
        ))}
        {isThinking && (
          <div className="text-center">
            <div className="inline-block p-2 bg-gray-700 rounded-lg">
              <div className="flex items-center">
                <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-500 mr-2"></div>
                <span className="text-gray-300">Thinking...</span>
              </div>
            </div>
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit} className="flex p-2">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onFocus={handleFocus}
          placeholder={placeholder}
          className="flex-grow p-3 bg-gray-700 text-white rounded-l-lg focus:outline-none focus:ring-1 focus:ring-blue-500 border-0"
          aria-label="Chat message input"
        />
        <button 
          type="submit" 
          className={`px-6 rounded-r-lg focus:outline-none focus:ring-1 focus:ring-blue-500 ${
            message.trim() 
              ? 'bg-blue-500 hover:bg-blue-600 text-white' 
              : 'bg-gray-600 text-gray-400 cursor-not-allowed'
          }`}
          disabled={!message.trim()}
        >
          Send
        </button>
      </form>
    </div>
  );
};

ChatInterface.propTypes = {
  onSendMessage: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(PropTypes.shape({
    sender: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  })).isRequired,
  isThinking: PropTypes.bool.isRequired,
  exampleText: PropTypes.string.isRequired
};

export default ChatInterface;
