import React from "react";
import {
  FileIcon,
  FolderIcon,
  FileTextIcon,
  FileJsonIcon,
  FileCodeIcon,
  LayoutTemplate,
  Settings,
  Binary,
  Coffee,
  Table,
  Code,
} from "lucide-react";

export const getFileIcon = (node, size = 16) => {
  // Handle both node objects and direct file names
  const fileName = typeof node === "string" ? node : node.name;
  const isFolder = typeof node === "object" && node.type === "folder";

  if (isFolder) {
    return <FolderIcon size={size} className="text-gray-500" />;
  }

  const extension = fileName.split(".").pop().toLowerCase();
  const iconProps = { size, className: "text-yellow-400" };

  switch (extension) {
    case "java":
      return <Coffee {...iconProps} />;
    case "html":
      return <LayoutTemplate {...iconProps} />;
    case "xml":
      return <Settings {...iconProps} />;
    case "js":
    case "jsx":
      return <FileCodeIcon {...iconProps} />;
    case "cpy":
    case "cbl":
      return <Binary {...iconProps} />;
    case "json":
      return <FileJsonIcon {...iconProps} />;
    case "md":
    case "txt":
      return <FileTextIcon {...iconProps} />;
    case "table":
    case "column":
      return <Table size={16} className="text-yellow-400" />;
    case "class":
    case "method":
      return <Code size={16} className="text-yellow-400" />;
    default:
      return <FileIcon {...iconProps} />;
  }
};
