import React, { useContext, useMemo } from 'react';
import { AppContext } from '../../context/AppContext';
import { FileText, Brain } from 'lucide-react';

const Dashboard = () => {
  const { analysisContent, dependencyImpactContent } = useContext(AppContext);

  const metrics = useMemo(() => {
    if (!analysisContent) {
      return {
        complexity: { score: '--', label: 'XS' },
        filesCount: '--',
        linesOfCode: '--',
      };
    }

    // Calculate total files (including dependencies)
    const mainFiles = analysisContent.changes?.length || 0;
    const dependencyFiles = dependencyImpactContent?.dependency_impacts?.length || 0;
    const totalFiles = mainFiles + dependencyFiles;

    // Calculate total lines of code
    const linesOfCode = analysisContent.changes?.reduce((acc, change) => {
      return acc + (change.changes?.length || 0);
    }, 0) || 0;

    // Calculate complexity score
    const getComplexityScore = (files, lines) => {
      const score = (files * 10) + (lines * 0.5);
      if (score < 30) return { score, label: 'XS' };
      if (score < 60) return { score, label: 'S' };
      if (score < 100) return { score, label: 'M' };
      if (score < 150) return { score, label: 'L' };
      return { score, label: 'XL' };
    };

    return {
      complexity: getComplexityScore(totalFiles, linesOfCode),
      filesCount: totalFiles,
      linesOfCode,
    };
  }, [analysisContent, dependencyImpactContent]);

  return (
    <div className="h-full flex flex-col">
      <div className="p-4">
        <div className="space-y-3">
          <div className="bg-gray-900 rounded-lg p-4 flex items-start gap-3">
            <div className="p-2 bg-blue-500/10 rounded-lg">
              <Brain className="w-5 h-5 text-blue-400" />
            </div>
            <div>
              <div className="text-gray-400 text-sm mb-1">Complexity Score</div>
              <div className="flex items-baseline gap-2">
                <div className="text-white text-2xl font-semibold">{metrics.complexity.label}</div>
                <div className="text-gray-500 text-sm">Score: {metrics.complexity.score}</div>
              </div>
            </div>
          </div>

          <div className="bg-gray-900 rounded-lg p-4 flex items-start gap-3">
            <div className="p-2 bg-green-500/10 rounded-lg">
              <FileText className="w-5 h-5 text-green-400" />
            </div>
            <div>
              <div className="text-gray-400 text-sm mb-1">Impact Scope</div>
              <div className="flex flex-col">
                <div className="text-white text-2xl font-semibold">{metrics.filesCount} files</div>
                <div className="text-gray-500 text-sm">{metrics.linesOfCode} lines of code</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard; 