import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

const FileBox = ({ children }) => (
  <span className="inline-flex items-center px-2 py-0.5 rounded bg-blue-500/10 border border-blue-300/20 text-blue-300 font-mono text-sm">
    {children}
  </span>
);

FileBox.propTypes = {
  children: PropTypes.node,
};

const MethodBox = ({ children }) => (
  <span className="inline-flex items-center px-2 py-0.5 rounded bg-purple-500/10 border border-purple-300/20 text-purple-300 font-mono text-sm">
    {children}
  </span>
);

MethodBox.propTypes = {
  children: PropTypes.node,
};

// Update the helper function to capitalize only first letters
const formatEnrichedFeatureRequest = (text) => {
  if (!text) return "";

  return text
    .replace(/<(\w+)>/g, (_, tag) => {
      const words = tag.split("_");
      const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );
      return `\n\n**${capitalizedWords.join(" ")}:**\n\n`;
    })
    .replace(/<\/\w+>/g, "\n\n")
    .trim();
};

const RequestedFeature = ({
  analysisText,
  isEditing,
  setIsEditing,
  onAnalysisTextChange,
}) => {
  const {
    sendMessage,
    currentStatus,
    selectedNamespace,
    setSelectedNamespace,
    NAMESPACES,
    enrichedFeatureRequest,
  } = useContext(AppContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [isMockEnabled, setIsMockEnabled] = useState(
    searchParams.get("mock") === "true"
  );
  const [showDependencyImpact, setShowDependencyImpact] = useState(
    searchParams.get("dependency_impact") === "true"
  );
  const [showSolutionConfig, setSolutionConfig] = useState(
    searchParams.get("solution_config") === "true"
  );
  const [showMiniGraph, setShowMiniGraph] = useState(
    searchParams.get("mini_graph") === "true"
  );
  const [showSuggestEdits, setShowSuggestEdits] = useState(
    searchParams.get("suggest_edits") === "true"
  );

  const textareaRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const params = {};
    if (isMockEnabled) params.mock = "true";
    if (showDependencyImpact) params.dependency_impact = "true";
    if (showSolutionConfig) params.solution_config = "true";
    if (showMiniGraph) params.mini_graph = "true";
    if (showSuggestEdits) params.suggest_edits = "true";
    setSearchParams(params);
  }, [
    isMockEnabled,
    showDependencyImpact,
    showSolutionConfig,
    showMiniGraph,
    showSuggestEdits,
    setSearchParams,
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isEditing &&
        textareaRef.current &&
        !textareaRef.current.contains(event.target) &&
        containerRef.current &&
        !containerRef.current.contains(event.target.closest(".bg-blue-500")) // Ignore clicks on Start Analysis button
      ) {
        setIsEditing(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing, setIsEditing]);

  console.log("🔍 Component render:", {
    enrichedFeatureRequest,
    currentStatus,
    isEditing,
    isAnalysisInProgress: currentStatus && currentStatus !== "done",
    analysisText,
  });

  const [isInputActive, setIsInputActive] = useState(false);

  const handleInitialClick = () => {
    if (!isInputActive) {
      setIsInputActive(true);
      setIsEditing(true);
    } else {
      setIsEditing(true);
    }
  };

  const handleStartAnalysis = () => {
    if (analysisText) {
      const params = {
        mock: isMockEnabled,
        dependency_impact: showDependencyImpact,
        solution_config: showSolutionConfig,
        mini_graph: showMiniGraph,
        suggest_edits: showSuggestEdits
      };
      sendMessage({
        feature_request: analysisText,
        params: params,
      });
      setIsEditing(false);
    }
  };

  const formattedEnrichedFeatureRequest = useMemo(() => {
    return formatEnrichedFeatureRequest(enrichedFeatureRequest);
  }, [enrichedFeatureRequest]);

  console.log(enrichedFeatureRequest, "enrichedFeatureRequest");
  return (
    <div
      ref={containerRef}
      className={`bg-gray-800 border border-gray-700 rounded-lg ${
        !enrichedFeatureRequest ? 'max-h-[calc(100vh-24rem)]' : ''
      } h-fit flex flex-col`}
    >
      {/* Fixed header */}
      <div className="p-4 border-b border-gray-700 bg-gray-800">
        <h2 className="text-xl font-bold mb-2">Requested Feature</h2>
        <select
          className="w-full bg-gray-900 border border-gray-700 rounded-lg p-1.5 text-sm text-white focus:outline-none focus:border-blue-500"
          value={selectedNamespace}
          onChange={(e) => setSelectedNamespace(e.target.value)}
        >
          {Object.entries(NAMESPACES).map(([key, value]) => (
            <option key={key} value={value}>
              {key}
            </option>
          ))}
        </select>
      </div>

      {/* Scrollable content - remove flex-1 and add h-fit */}
      <div className="h-fit overflow-auto p-4">
        {/* Feature request input/display */}
        <div className="mb-4">
          {isEditing ? (
            <textarea
              ref={textareaRef}
              className="w-full bg-gray-900 border border-gray-700 rounded-lg p-3 text-white resize-y focus:outline-none focus:border-blue-500"
              value={analysisText}
              onChange={(e) => onAnalysisTextChange(e.target.value)}
              placeholder="Describe the feature you want to implement..."
              rows="5"
            />
          ) : (
            <div
              className="w-full bg-gray-900 border border-gray-700 rounded-lg p-3 text-white cursor-pointer"
              onClick={handleInitialClick}
            >
              {analysisText}
            </div>
          )}
        </div>

        {/* Enriched feature request display */}
        {enrichedFeatureRequest && !isEditing && (
          <div className="bg-blue-500/10 border border-blue-500/20 rounded-lg p-4">
            <h3 className="text-sm font-semibold text-blue-400 mb-2">
              Enriched Feature Request
            </h3>
            <div className="max-h-[400px] overflow-y-auto pr-2">
              <ReactMarkdown
                className="text-gray-300 prose prose-invert max-w-none text-sm"
                components={{
                  p: ({ children }) => <p className="mb-3">{children}</p>,
                  ul: ({ children }) => (
                    <ul className="list-disc pl-4 mb-3">{children}</ul>
                  ),
                  li: ({ children }) => <li className="mb-2">{children}</li>,
                  h2: ({ children }) => (
                    <h2 className="text-lg font-semibold text-blue-300 mt-4 mb-3">
                      {children}
                    </h2>
                  ),
                  h3: ({ children }) => (
                    <h3 className="text-md font-semibold text-blue-300 mt-3 mb-2">
                      {children}
                    </h3>
                  ),
                  strong: ({ children }) => (
                    <strong className="text-blue-200">{children}</strong>
                  ),
                  method: ({ children }) => <MethodBox>{children}</MethodBox>,
                  file: ({ children }) => <FileBox>{children}</FileBox>,
                  table: ({ children }) => (
                    <span className="text-yellow-300">{children}</span>
                  ),
                  variable: ({ children }) => (
                    <span className="text-green-300">{children}</span>
                  ),
                }}
              >
                {formattedEnrichedFeatureRequest}
              </ReactMarkdown>
            </div>
          </div>
        )}
      </div>

      {/* Fixed footer with action buttons */}
      
        <div className="p-4 border-t border-gray-700 bg-gray-800">
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col space-y-3">
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={isMockEnabled}
                  onChange={(e) => setIsMockEnabled(e.target.checked)}
                />
                <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-500"></div>
                <span className="ms-3 text-sm font-medium text-gray-300">
                  Mock Mode
                </span>
              </label>

              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={showDependencyImpact}
                  onChange={(e) => setShowDependencyImpact(e.target.checked)}
                />
                <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-500"></div>
                <span className="ms-3 text-sm font-medium text-gray-300">
                  Dependency Impact
                </span>
              </label>

              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={showSolutionConfig}
                  onChange={(e) => setSolutionConfig(e.target.checked)}
                />
                <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-500"></div>
                <span className="ms-3 text-sm font-medium text-gray-300">
                  Solution Config
                </span>
              </label>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={showMiniGraph}
                  onChange={(e) => setShowMiniGraph(e.target.checked)}
                />
                <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-500"></div>
                <span className="ms-3 text-sm font-medium text-gray-300">
                  Mini Graph
                </span>
              </label>

              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={showSuggestEdits}
                  onChange={(e) => setShowSuggestEdits(e.target.checked)}
                />
                <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-500"></div>
                <span className="ms-3 text-sm font-medium text-gray-300">
                  Suggest Edits
                </span>
              </label>
            </div>

            <button
              className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-400"
              onClick={handleStartAnalysis}
              disabled={!analysisText}
            >
              Start Analysis
            </button>
          </div>
        </div>
    </div>
  );
};

RequestedFeature.propTypes = {
  analysisText: PropTypes.string,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  onAnalysisTextChange: PropTypes.func.isRequired,
};

export default RequestedFeature;
