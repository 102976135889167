export const preprocessText = (text) => {
  if (!text) return "";

  // Replace <table> tags with custom markers before markdown processing
  return text.replace(
    /<table>(.*?)<\/table>/g,
    "<table-custom>$1</table-custom>"
  );
};

export const formatMarkdownText = (text) => {
  if (!text) return "";

  // Split by 'undefined -' and filter out empty strings
  return text.replaceAll("undefined -", "");
};

// Add helper function to get distinctive file name
export const getDistinctiveFileName = (filePath) => {
  if (!filePath) return "";

  // Split the path into parts
  const parts = filePath.split("/");

  // Get the file name (last part)
  const fileName = parts[parts.length - 1];

  // If the path has multiple parts and the filename isn't unique enough,
  // include the parent directory
  if (parts.length > 1 && fileName.length < 15) {
    return `${parts[parts.length - 2]}/${fileName}`;
  }

  return fileName;
};

// Helper function to get the last two folders
export const getLastTwoFolders = (filePath) => {
  const parts = filePath.split("/");
  const folders = parts
    .slice(0, -1) // Remove the filename
    .filter((part) => part.length > 0); // Remove empty parts

  if (folders.length < 2) {
    return folders.join("/");
  }

  return `${folders[folders.length - 2]}/${folders[folders.length - 1]}`;
};
