// Data model service to handle processing and storage
class DataModelService {
  constructor() {
    this.fileDataModel = {};
  }

  // Helper to extract file name from path
  getFileNameFromPath(filePath) {
    return filePath.split("/").pop();
  }

  // Process relevant files content
  processRelevantFiles(content) {
    if (!content.Files) return;

    const files = Array.isArray(content.Files)
      ? content.Files
      : [content.Files];

    files.forEach((file) => {
      if (file.file_path && file.relevancy) {
        this.updateFileData(file.file_path, {
          file_metadata: {
            status: file.status,
            lastUpdated: new Date().toISOString(),
          },
          relevant_files: {
            relevancy: file.relevancy,
            textual_representation: file.textual_representation,
            assessment_of_relevance: file.assessment_of_relevance,
          },
        });
      }
    });

    return files.filter((file) => file.relevancy === "likely");
  }

  // Process solution plan content
  processSolutionPlan(content) {
    const { solution_overview } = content;
    console.log(solution_overview, "solution_overview");
    solution_overview.forEach((item) => {
      item.files.forEach((filePath) => {
        this.updateFileData(filePath, {
          solution_plan: {
            highlights: solution_overview
              .filter((plan) => plan.files.includes(filePath))
              .map((plan) => ({
                highlight: plan.highlight,
                explanation: plan.explanation,
              })),
          },
        });
      });
    });

    return {
      solution_plan: solution_overview
        .map((item) => `${item.highlight} - ${item.explanation}`)
        .join("\n\n")
        
    };
  }

  // Process suggested edits content
  processSuggestedEdits(content) {
    console.log("🔍 Processing suggested edits:", content);
    if (!content.code_highlights) return null;

    const changesByFile = new Map();

    content.code_highlights.forEach((highlight) => {
      console.log("📝 Processing highlight:", {
        filePath: highlight.file_path,
        codeChanges: highlight.code_changes,
      });

      if (highlight.file_path) {
        // Update the file data model
        this.updateFileData(highlight.file_path, {
          suggest_edits: [
            {
              change_description: highlight.change_description,
              highlighted_code: highlight.highlighted_code,
              code_changes: highlight.code_changes,
            },
          ],
        });

        // Group changes for UI
        const existingChanges = changesByFile.get(highlight.file_path) || {
          file_path: highlight.file_path,
          changes: [],
        };

        existingChanges.changes.push({
          change_description: highlight.change_description,
          explanation: highlight.change_description,
          highlighted_code: highlight.highlighted_code,
          code_changes: highlight.code_changes,
        });

        changesByFile.set(highlight.file_path, existingChanges);
      }
    });

    const result = {
      code_highlights: content.code_highlights,
      changes: Array.from(changesByFile.values()),
    };

    console.log("✨ Processed suggested edits result:", result);
    return result;
  }

  // Process dependency impact content
  processDependencyImpact(content) {
    if (content.file_path) {
      this.updateFileData(content.file_path, {
        dependency_impact: {
          changes: [
            {
              change_description: content.change_description,
              highlighted_code: content.highlighted_code,
            },
          ],
          impacted_files: [content.key_change_file_path].filter(Boolean),
        },
      });
    }

    return content;
  }

  // Update file data with automatic file name extraction
  updateFileData(filePath, newData) {
    this.fileDataModel[filePath] = {
      ...this.fileDataModel[filePath],
      file_name: this.getFileNameFromPath(filePath), // Add file name
      ...newData,
    };

    // Merge arrays instead of replacing them
    ["suggest_edits", "changes"].forEach((arrayKey) => {
      if (newData[arrayKey]) {
        this.fileDataModel[filePath][arrayKey] = [
          ...(this.fileDataModel[filePath][arrayKey] || []),
          ...newData[arrayKey],
        ];
      }
    });

    return this.fileDataModel[filePath];
  }

  // Get the entire file data model
  getFileDataModel() {
    return this.fileDataModel;
  }

  // Process mini graph content
  processMiniGraph(content) {
    console.log("🔍 Processing mini graph input:", content);

    if (!content?.nodes || !content?.edges) {
      console.warn("⚠️ Invalid mini graph content:", content);
      return null;
    }

    try {
      // Validate nodes and edges
      const validNodes = content.nodes.filter((node) => {
        const isValid = node && node.name;
        if (!isValid) console.warn("⚠️ Invalid node:", node);
        return isValid;
      });

      const validEdges = content.edges.filter((edge) => {
        const isValid = edge && edge.node1?.name && edge.node2?.name;
        if (!isValid) console.warn("⚠️ Invalid edge:", edge);
        return isValid;
      });

      console.log("✅ Valid nodes:", validNodes.length);
      console.log("✅ Valid edges:", validEdges.length);

      // Create maps to store node and edge references
      const nodeReferences = new Map();
      const edgeReferences = new Map();

      // Process nodes
      validNodes.forEach((node) => {
        nodeReferences.set(node.name, {
          id: node.name,
          name: node.name,
          type: node.type || "unknown",
          references: [],
        });
      });

      // Process edges
      validEdges.forEach((edge) => {
        const sourceNode = edge.node1.name;
        const targetNode = edge.node2.name;

        // Add edge references to source node
        if (nodeReferences.has(sourceNode)) {
          nodeReferences.get(sourceNode).references.push({
            type: edge.type || "unknown",
            direction: "outgoing",
            connectedTo: targetNode,
          });
        }

        // Add edge references to target node
        if (nodeReferences.has(targetNode)) {
          nodeReferences.get(targetNode).references.push({
            type: edge.type || "unknown",
            direction: "incoming",
            connectedTo: sourceNode,
          });
        }

        // Store edge reference with guaranteed unique IDs
        const edgeKey = `${sourceNode}-${targetNode}`;
        edgeReferences.set(edgeKey, {
          id: edgeKey,
          source: sourceNode,
          target: targetNode,
          type: edge.type || "unknown",
        });
      });

      // Update file data model
      nodeReferences.forEach((nodeData, filePath) => {
        if (filePath && filePath.startsWith("code_samples/")) {
          this.updateFileData(filePath, {
            mini_graph: {
              node_type: nodeData.type,
              references: nodeData.references,
            },
          });
        }
      });

      const processedData = {
        nodes: Array.from(nodeReferences.values()),
        edges: Array.from(edgeReferences.values()),
        findNodeReferences: (nodeName) =>
          nodeReferences.get(nodeName)?.references || [],
        findConnectedNodes: (nodeName) => {
          const node = nodeReferences.get(nodeName);
          return node ? node.references.map((ref) => ref.connectedTo) : [];
        },
      };

      console.log("✅ Final processed data:", processedData);
      return processedData;
    } catch (error) {
      console.error("❌ Error in processMiniGraph:", error);
      return null;
    }
  }
}

export default new DataModelService();
