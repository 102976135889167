import React, { useEffect, useRef, useMemo } from 'react';
import { Network } from 'vis-network';
import PropTypes from 'prop-types';

// Predefined color palette for consistent colors
const COLOR_PALETTE = [
  '#4CAF50', // Green
  '#FF5722', // Deep Orange
  '#2196F3', // Blue
  '#9C27B0', // Purple
  '#FFC107', // Amber
  '#00BCD4', // Cyan
  '#F44336', // Red
  '#3F51B5', // Indigo
  '#FFEB3B', // Yellow
  '#009688', // Teal
  '#E91E63', // Pink
  '#673AB7', // Deep Purple
  '#8BC34A', // Light Green
  '#FF9800', // Orange
  '#03A9F4', // Light Blue
];

// Update the color constants
const NODE_COLORS = {
  KEY_CHANGES: '#FF5722',    // Deep Orange for key changes
  IMPACTED_CODE: '#2196F3',  // Blue for impacted code
  RELATED_CODE: '#9C27B0'    // Purple for related code
};

export function GraphVisualization({ graphData = { nodes: [], edges: [] }, isFullScreen }) {
  const containerRef = useRef(null);
  const networkRef = useRef(null);

  // Process graph data
  const { nodeColorMap, edgeColorMap } = useMemo(() => {
    const nodeColorMap = new Map();
    const edgeColorMap = new Map();
    const edgeTypes = new Set(graphData?.edges?.map(edge => edge.type) || []);

    // Assign colors to nodes based on their data model properties
    graphData?.nodes?.forEach(node => {
      if (node.data?.suggest_edits?.length > 0) {
        nodeColorMap.set(node.id, NODE_COLORS.KEY_CHANGES);
      } else if (node.data?.dependency_impact?.changes?.length > 0) {
        nodeColorMap.set(node.id, NODE_COLORS.IMPACTED_CODE);
      } else {
        nodeColorMap.set(node.id, NODE_COLORS.RELATED_CODE);
      }
    });

    // Keep existing edge color logic
    let colorIndex = 0;
    edgeTypes.forEach(type => {
      edgeColorMap.set(type, COLOR_PALETTE[colorIndex % COLOR_PALETTE.length]);
      colorIndex++;
    });

    return { nodeColorMap, edgeColorMap };
  }, [graphData]);

  // Update the visGraphData processing
  const visGraphData = useMemo(() => {
    console.log('🔄 Processing graph data:', {
        nodes: graphData?.nodes,
        edges: graphData?.edges
    });

    if (!graphData?.nodes || !graphData?.edges) {
        return { nodes: [], edges: [] };
    }

    // Create map of valid nodes for lookup
    const nodeMap = new Map(
        graphData.nodes.map(node => [node.id, node])
    );

    // Process nodes
    const nodes = graphData.nodes.map(node => {
      let nodeType = 'RELATED_CODE';
      if (node.data?.suggest_edits?.length > 0) {
        nodeType = 'KEY_CHANGES';
      } else if (node.data?.dependency_impact?.changes?.length > 0) {
        nodeType = 'IMPACTED_CODE';
      }

      // Extract just the filename from the full path
      const fileName = node.id.split('/').pop();

      return {
        id: node.id,
        label: fileName,
        title: node.id,
        color: nodeColorMap.get(node.id),
        data: { ...node.data, nodeType },
        group: nodeType
      };
    });

    // Process and validate edges
    const edges = graphData.edges.filter(edge => {
        // Validate that both source and target nodes exist
        const sourceNode = nodeMap.get(edge.source);
        const targetNode = nodeMap.get(edge.target);
        return sourceNode && targetNode;
    }).map(edge => ({
        id: edge.id,
        from: edge.source,
        to: edge.target,
        color: edgeColorMap.get(edge.type) || '#808080',
        arrows: 'to',
        title: `Type: ${edge.type}`,
        data: edge
    }));

    console.log('✅ Processed graph data:', { nodes, edges });
    return { nodes, edges };
  }, [graphData, nodeColorMap, edgeColorMap]);

  const options = useMemo(() => ({
    layout: {
      hierarchical: {
        enabled: false
      },
      improvedLayout: true,
      randomSeed: 2
    },
    edges: {
      color: '#ffffff',
      arrows: {
        to: { enabled: true, scaleFactor: 0.5 }
      },
      smooth: {
        enabled: true,
        type: 'curvedCW',
        roundness: 0.2,
        forceDirection: 'none'
      },
      font: {
        size: 14,
        color: '#ffffff',
        align: 'middle',
        background: '#1f2937',
        strokeWidth: 0,
        padding: 6,
        face: 'monospace',
        vadjust: 0
      },
      selfReferenceSize: 20,
      physics: true,
      width: 2,
      length: 300,
    },
    nodes: {
      shape: 'dot',
      size: 16,
      font: {
        size: 12,
        color: '#ffffff',
        face: 'monospace'
      },
      borderWidth: 2,
      fixed: {
        x: false,
        y: false
      }
    },
    physics: {
      enabled: true,
      forceAtlas2Based: {
        gravitationalConstant: -500,
        centralGravity: 0.01,
        springLength: 200,
        springConstant: 0.08,
        avoidOverlap: 1
      },
      solver: 'forceAtlas2Based',
      stabilization: {
        enabled: true,
        iterations: 100,
        updateInterval: 25,
        fit: true
      }
    },
    interaction: {
      zoomView: true,
      hover: true
    }
  }), []); // Empty dependency array since options are static

  // Initialize network
  useEffect(() => {
    if (containerRef.current && visGraphData.nodes.length > 0) {
      networkRef.current = new Network(
        containerRef.current,
        visGraphData,
        options
      );

      // Add event listeners
      networkRef.current.on('select', (params) => {
        const { nodes, edges } = params;
        console.log('Selected nodes:', nodes?.map(id => {
          const node = visGraphData.nodes.find(n => n.id === id);
          return node?.data;
        }));
        console.log('Selected edges:', edges?.map(id => {
          const edge = visGraphData.edges.find(e => e.id === id);
          return edge?.data;
        }));
      });

      networkRef.current.on('stabilizationIterationsDone', () => {
        console.log('📊 Graph stabilization complete');
        // Stop physics after stabilization
        setTimeout(() => {
          networkRef.current.setOptions({ physics: { enabled: false } });
        }, 1000);
      });
    }

    return () => {
      if (networkRef.current) {
        networkRef.current.destroy();
        networkRef.current = null;
      }
    };
  }, [visGraphData, options]);

  // Handle full screen changes
  useEffect(() => {
    if (networkRef.current) {
      networkRef.current.setSize('100%', '100%');
      networkRef.current.fit();
    }
  }, [isFullScreen]);

  if (!graphData?.nodes?.length) {
    return <div className="flex items-center justify-center h-full text-gray-400">No graph data available</div>;
  }

  return (
    <div ref={containerRef} style={{ height: '100%', width: '100%' }} />
  );
}

// Add PropTypes validation for GraphVisualization
GraphVisualization.propTypes = {
  graphData: PropTypes.shape({
    nodes: PropTypes.array,
    edges: PropTypes.array
  }),
  isFullScreen: PropTypes.bool
};

// Update the Legend component to use the new categorization
export function Legend({ legend = { nodes: [], edges: [] } }) {
  const nodeCategories = [
    { type: 'KEY_CHANGES', label: 'Key Changes', color: NODE_COLORS.KEY_CHANGES },
    { type: 'IMPACTED_CODE', label: 'Impacted Code', color: NODE_COLORS.IMPACTED_CODE },
    { type: 'RELATED_CODE', label: 'Related Code', color: NODE_COLORS.RELATED_CODE }
  ];

  return (
    <div className="legend h-full flex flex-col">
      <h3 className="text-sm font-semibold p-3 border-b border-gray-700">Legend</h3>
      <div className="flex-grow overflow-y-auto">
        <div className="p-3">
          <h4 className="text-xs font-medium mb-2">Nodes</h4>
          {nodeCategories.map(({ type, label, color }) => (
            <div key={type} className="flex items-center mb-2">
              <div 
                style={{ 
                  width: 12, 
                  height: 12, 
                  backgroundColor: color 
                }} 
                className="mr-3 rounded-full flex-shrink-0"
              ></div>
              <span className="text-xs break-all">{label}</span>
            </div>
          ))}
        </div>
        <div className="p-3 border-t border-gray-700">
          <h4 className="text-xs font-medium mb-2">Edges</h4>
          {Array.from(new Set(legend.edges.map(edge => edge.type))).map((type, index) => {
            const item = legend.edges.find(edge => edge.type === type);
            const colorIndex = nodeCategories.length + index;
            return (
              <div key={type} className="flex items-center mb-2">
                <div 
                  style={{ 
                    width: 12, 
                    height: 3, 
                    backgroundColor: COLOR_PALETTE[colorIndex % COLOR_PALETTE.length] 
                  }} 
                  className="mr-3 flex-shrink-0"
                ></div>
                <span className="text-xs break-all">{type} ({item?.count || 0})</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

// Add PropTypes validation for Legend
Legend.propTypes = {
  legend: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      count: PropTypes.number
    })),
    edges: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      count: PropTypes.number
    }))
  })
};
