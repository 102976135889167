import React, { useState, useContext } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { AppContext } from '../../context/AppContext';
import { ProcessMapProvider } from './context/ProcessMapContext';
import StatusBox from '../../components/StatusBox/StatusBox';
import ProcessConfig from './components/ProcessConfig';

const ProcessMapContent = () => {
  const { currentStatus, textStatus } = useContext(AppContext);
  const [showDataModel, setShowDataModel] = useState(false);

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col">
      {/* Main content area - no padding on bottom */}
      <div className="p-4 pb-0 flex-grow">
        {/* Main Grid Layout */}
        <div className="grid grid-cols-1 gap-4 h-[calc(100vh-2rem)]">
          <PanelGroup direction="horizontal" className="h-full" id="root">
            {/* Left Panel */}
            <Panel id="left" defaultSize={20} minSize={15} className="flex flex-col">
              <PanelGroup direction="vertical" id="left-group">
                <Panel id="process-config" defaultSize={50} minSize={20}>
                  <ProcessConfig />
                </Panel>

                <PanelResizeHandle className="h-1.5 bg-gray-900 hover:bg-blue-500 transition-colors" />

                <Panel id="process-list" defaultSize={50} minSize={20}>
                  <div className="h-full bg-gray-800 rounded-lg border border-gray-700 overflow-hidden">
                    {/* ProcessList component will go here */}
                  </div>
                </Panel>
              </PanelGroup>
            </Panel>

            <PanelResizeHandle className="w-1.5 bg-gray-900 hover:bg-blue-500 transition-colors" />

            {/* Middle Panel */}
            <Panel id="middle" defaultSize={50} minSize={30}>
              <div className="h-full flex flex-col gap-4">
                <div className="flex-1 bg-gray-800 rounded-lg border border-gray-700 overflow-auto">
                  {/* ProcessMapViewer component will go here */}
                </div>
                <StatusBox currentStatus={currentStatus} textStatus={textStatus} />
              </div>
            </Panel>

            <PanelResizeHandle className="w-1.5 bg-gray-900 hover:bg-blue-500 transition-colors" />

            {/* Right Panel */}
            <Panel id="right" defaultSize={30} minSize={20} className="flex flex-col">
              <PanelGroup direction="vertical" id="right-group">
                <Panel id="process-details" defaultSize={50} minSize={20}>
                  <div className="h-full bg-gray-800 rounded-lg border border-gray-700 overflow-hidden">
                    {/* ProcessDetails component will go here */}
                  </div>
                </Panel>

                <PanelResizeHandle className="h-1.5 bg-gray-900 hover:bg-blue-500 transition-colors" />

                <Panel id="process-metrics" defaultSize={50} minSize={20}>
                  <div className="h-full bg-gray-800 rounded-lg border border-gray-700 overflow-hidden">
                    {/* ProcessMetrics component will go here */}
                  </div>
                </Panel>
              </PanelGroup>
            </Panel>
          </PanelGroup>
        </div>
      </div>

      {/* Data Model Section - Always visible at bottom */}
      <div className="p-4 bg-gray-900">
        {showDataModel ? (
          <div className="bg-gray-800 rounded-lg border border-gray-700 overflow-hidden">
            <div className="flex justify-end p-2 sticky top-0 bg-gray-800 z-10 border-b border-gray-700">
              <button
                onClick={() => setShowDataModel(false)}
                className="bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded-lg transition-colors"
              >
                Hide Data Model
              </button>
            </div>
            <div className="max-h-[70vh] overflow-auto">
              {/* DataModelViewer component will go here */}
            </div>
          </div>
        ) : (
          <div className="flex justify-end">
            <button
              onClick={() => {
                setShowDataModel(true);
                setTimeout(() => {
                  window.scrollTo({
                    top: document.documentElement.scrollHeight,
                    behavior: 'smooth'
                  });
                }, 100);
              }}
              className="bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded-lg transition-colors"
            >
              Show Data Model
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const ProcessMap = () => {
  return (
    <ProcessMapProvider>
      <ProcessMapContent />
    </ProcessMapProvider>
  );
};

export default ProcessMap; 