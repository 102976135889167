import React, { useMemo, useState, useContext } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { GraphVisualization, Legend } from './GraphVisualization';
import { AppContext } from '../../context/AppContext';
import PropTypes from 'prop-types';

const GraphDisplay = ({ showLegend = true }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { miniGraphContent } = useContext(AppContext);

  const processedData = useMemo(() => {
    if (!miniGraphContent) return { nodes: [], edges: [], legend: { nodes: [], edges: [] } };

    // Process nodes and edges to generate legend data
    const nodeTypes = new Map();
    const edgeTypes = new Map();

    miniGraphContent.nodes.forEach(node => {
      // Get the file extension and convert to uppercase for display
      const fileExt = node.name.split('.').pop().toUpperCase();
      const count = nodeTypes.get(fileExt) || 0;
      nodeTypes.set(fileExt, count + 1);
    });

    miniGraphContent.edges.forEach(edge => {
      const count = edgeTypes.get(edge.type) || 0;
      edgeTypes.set(edge.type, count + 1);
    });

    return {
      nodes: miniGraphContent.nodes.map(node => ({
        ...node,
        // Add displayType for legend grouping
        displayType: node.name.split('.').pop().toUpperCase(),
        // Keep original label for node display
        label: node.name
      })),
      edges: miniGraphContent.edges,
      legend: {
        nodes: Array.from(nodeTypes.entries()).map(([type, count]) => ({
          type,  // This will now be the uppercase file extension
          count
        })),
        edges: Array.from(edgeTypes.entries()).map(([type, count]) => ({
          type,
          count
        }))
      }
    };
  }, [miniGraphContent]);

  const visualizationData = {
    nodes: processedData.nodes || [],
    edges: processedData.edges || []
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <div className={`bg-gray-900 text-white rounded-lg border border-gray-700 overflow-hidden ${isFullScreen ? 'fixed inset-0 z-50' : 'h-full'}`}>
      <PanelGroup direction="horizontal">
        <Panel defaultSize={70} minSize={50}>
          <div className="h-full relative p-4">
            <GraphVisualization 
              graphData={visualizationData} 
              isFullScreen={isFullScreen}
            />
            <button
              onClick={toggleFullScreen}
              className="absolute top-4 right-4 bg-gray-700 text-white px-3 py-1.5 rounded-md hover:bg-gray-600 border border-gray-600"
            >
              {isFullScreen ? 'Minimize' : 'Expand'}
            </button>
          </div>
        </Panel>
        
        {showLegend && (
          <>
            <PanelResizeHandle className="w-1.5 bg-gray-800 hover:bg-blue-500 transition-colors" />
            <Panel defaultSize={30} minSize={20}>
              <div className="h-full border-l border-gray-700 bg-gray-800/50 p-4">
                <Legend legend={processedData.legend} />
              </div>
            </Panel>
          </>
        )}
      </PanelGroup>
    </div>
  );
};

GraphDisplay.propTypes = {
  showLegend: PropTypes.bool
};

export default GraphDisplay;
