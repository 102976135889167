import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from '../../context/AppContext';

// DataCell component for reuse
const DataCell = ({ content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!content) {
    return <span className="text-gray-500">-</span>;
  }

  return (
    <div className="max-w-md">
      <div 
        className="cursor-pointer hover:text-blue-400"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? (
          <div className="space-y-2">
            {Array.isArray(content) ? content.map((item, idx) => (
              <div key={idx} className="bg-gray-700 p-2 rounded">
                <div className="font-medium text-blue-300">
                  {item.change_description || item.explanation}
                </div>
                {item.highlighted_code && (
                  <div className="text-xs mt-1">
                    Lines: {Array.isArray(item.highlighted_code) 
                      ? item.highlighted_code.join(', ')
                      : item.highlighted_code}
                  </div>
                )}
              </div>
            )) : (
              <div className="bg-gray-700 p-2 rounded">
                <div className="text-xs">{JSON.stringify(content, null, 2)}</div>
              </div>
            )}
          </div>
        ) : (
          <div className="flex items-center">
            <span>{Array.isArray(content) ? `${content.length} items` : '1 item'}</span>
            <span className="ml-1 text-blue-400">...</span>
          </div>
        )}
      </div>
    </div>
  );
};

DataCell.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string
  ])
};

const SolutionPlanCell = ({ content }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  console.log('🎯 Solution Plan Cell Content:', content);

  if (!content?.highlights?.length) {
    return <span className="text-gray-500">-</span>;
  }

  return (
    <div className="max-w-md">
      <div 
        className="cursor-pointer hover:text-blue-400"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? (
          <div className="space-y-2">
            {content.highlights.map((item, idx) => (
              <div key={idx} className="bg-gray-700 p-2 rounded">
                <div className="text-sm">
                  {item.explanation}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex items-center space-x-1">
            <span className="text-blue-400">
              {content.highlights.length}
            </span>
            <span className="text-gray-400">
              planned changes
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

SolutionPlanCell.propTypes = {
  content: PropTypes.shape({
    highlights: PropTypes.arrayOf(PropTypes.shape({
      explanation: PropTypes.string
    }))
  })
};

const RelevantFilesCell = ({ content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!content) {
    return <span className="text-gray-500">-</span>;
  }

  return (
    <div className="max-w-md">
      <div 
        className="cursor-pointer hover:text-blue-400"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? (
          <div className="space-y-2">
            <div className="bg-gray-700 p-2 rounded">
              <div className="font-medium text-blue-300">
                Relevancy: {content.relevancy}
              </div>
              <div className="text-sm mt-1">
                {content.textual_representation}
              </div>
              <div className="text-xs mt-2 text-gray-400">
                {content.assessment_of_relevance}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            <span className={`${
              content.relevancy === 'likely' ? 'text-green-400' : 'text-gray-400'
            }`}>
              {content.relevancy}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

RelevantFilesCell.propTypes = {
  content: PropTypes.shape({
    relevancy: PropTypes.string,
    textual_representation: PropTypes.string,
    assessment_of_relevance: PropTypes.string
  })
};

const MiniGraphCell = ({ content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!content?.references) {
    return <span className="text-gray-500">-</span>;
  }

  return (
    <div className="max-w-md">
      <div 
        className="cursor-pointer hover:text-blue-400"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? (
          <div className="space-y-2">
            <div className="bg-gray-700 p-2 rounded">
              <div className="font-medium text-blue-300">
                Node Type: {content.node_type || 'unknown'}
              </div>
              <div className="text-sm mt-1">
                References: {content.references.length}
              </div>
              {content.references.map((ref, idx) => (
                <div key={idx} className="text-xs mt-1 text-gray-400">
                  {ref.direction}: {ref.connectedTo} ({ref.type})
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            <span>{content.references.length} connections</span>
            <span className="ml-1 text-blue-400">...</span>
          </div>
        )}
      </div>
    </div>
  );
};

MiniGraphCell.propTypes = {
  content: PropTypes.shape({
    node_type: PropTypes.string,
    references: PropTypes.arrayOf(PropTypes.shape({
      direction: PropTypes.string,
      connectedTo: PropTypes.string,
      type: PropTypes.string
    }))
  })
};

const DebugPanel = ({ fileDataModel }) => {
  const [isVisible, setIsVisible] = useState(false);
  
  return (
    <div className="mt-4">
      <button 
        onClick={() => setIsVisible(!isVisible)}
        className="text-xs text-blue-400 hover:text-blue-300"
      >
        {isVisible ? 'Hide' : 'Show'} Debug Info
      </button>
      
      {isVisible && (
        <pre className="mt-2 p-2 bg-gray-900 rounded text-xs overflow-auto max-h-[200px]">
          {JSON.stringify({
            fileCount: Object.keys(fileDataModel).length,
            filesWithSolutionPlan: Object.entries(fileDataModel)
              .filter(([, data]) => data.solution_plan?.entity_found)
              .length,
            modelState: fileDataModel
          }, null, 2)}
        </pre>
      )}
    </div>
  );
};

DebugPanel.propTypes = {
  fileDataModel: PropTypes.object.isRequired
};

const DataModelViewer = () => {
  const { fileDataModel } = useContext(AppContext);
  
  // Debug specific file
  const accountFile = "code_samples/aws_cics/src/webui/src/main/java/com/ibm/cics/cip/bankliberty/webui/data_access/Account.java";
  console.log('🔍 Account.java data:', fileDataModel[accountFile]);

  console.log('🔍 Current fileDataModel:', fileDataModel);

  return (
    <div className="bg-gray-800 rounded-lg p-4 overflow-auto max-h-[600px]">
      <table className="min-w-full">
        <thead>
          <tr>
            <th className="px-4 py-2 text-left">File Path</th>
            <th className="px-4 py-2 text-left">File Name</th>
            <th className="px-4 py-2 text-left">Relevant Files</th>
            <th className="px-4 py-2 text-left">Solution Plan</th>
            <th className="px-4 py-2 text-left">Suggested Edits</th>
            <th className="px-4 py-2 text-left">Dependency Impact</th>
            <th className="px-4 py-2 text-left">Mini Graph</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(fileDataModel).map(([filePath, data]) => (
            <tr key={filePath} className="border-t border-gray-700">
              <td className="px-4 py-2 font-mono text-sm">
                {filePath}
              </td>
              <td className="px-4 py-2 font-mono text-sm text-blue-400">
                {data.file_name}
              </td>
              <td className="px-4 py-2">
                <RelevantFilesCell content={data.relevant_files} />
              </td>
              <td className="px-4 py-2">
                <SolutionPlanCell content={data.solution_plan} />
              </td>
              <td className="px-4 py-2">
                <DataCell content={data.suggest_edits} />
              </td>
              <td className="px-4 py-2">
                <DataCell content={data.dependency_impact?.changes} />
              </td>
              <td className="px-4 py-2">
                <MiniGraphCell content={data.mini_graph} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4 flex justify-between text-xs text-gray-500">
        <span>Total Files: {Object.keys(fileDataModel).length}</span>
        <span>Last Updated: {new Date().toLocaleTimeString()}</span>
      </div>
      <DebugPanel fileDataModel={fileDataModel} />
    </div>
  );
};

export default DataModelViewer; 