import React, { useState, useContext, useEffect, useRef } from "react";
import { AppContext } from '../../../context/AppContext';
import { ProcessMapContext } from '../context/ProcessMapContext';

const ProcessConfig = () => {
  const { 
    sendMessage, 
    currentStatus, 
    selectedNamespace, 
    setSelectedNamespace, 
    NAMESPACES 
  } = useContext(AppContext);

  const {
    fileSearchResults,
    searchFiles,
    selectedProcessFiles,
    addProcessFile,
    removeProcessFile,
    processDescription,
    setProcessDescription,
  } = useContext(ProcessMapContext);

  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const searchInputRef = useRef(null);

  useEffect(() => {
    const handleSearch = async () => {
      console.log('Handling search for:', searchTerm);
      if (searchTerm.length >= 2) {
        await searchFiles(searchTerm);
      } else {
        searchFiles('');
      }
    };

    handleSearch();
  }, [searchTerm, searchFiles]);

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    console.log('Search input changed:', value);
    setSearchTerm(value);
  };

  return (
    <div className="h-full flex flex-col bg-gray-800 border border-gray-700 rounded-lg">
      <div className="p-4 flex flex-col flex-grow">
        <h2 className="text-xl font-bold text-white mb-2">Process Configuration</h2>
        
        {/* Namespace Selector */}
        <select
          className="bg-gray-900 border border-gray-700 rounded-lg p-1.5 text-sm text-white focus:outline-none focus:border-blue-500 mb-4 w-fit"
          value={selectedNamespace}
          onChange={(e) => setSelectedNamespace(e.target.value)}
        >
          {Object.entries(NAMESPACES).map(([key, value]) => (
            <option key={key} value={value}>
              {key}
            </option>
          ))}
        </select>

        {/* File Search Input */}
        <div className="relative mb-4">
          <input
            ref={searchInputRef}
            type="text"
            value={searchTerm}
            onChange={handleSearchInputChange}
            placeholder="Search files (e.g., ACCT01)"
            className="w-full bg-gray-900 border border-gray-700 rounded-lg p-2 text-white"
          />
          
          {/* Search Results Dropdown */}
          {fileSearchResults && fileSearchResults.length > 0 && (
            <div className="absolute w-full bg-gray-900 border border-gray-700 rounded-lg mt-1 z-10 max-h-48 overflow-y-auto">
              {fileSearchResults.map((file, index) => (
                <div
                  key={index}
                  className="p-2 hover:bg-gray-700 cursor-pointer text-white"
                  onClick={() => {
                    addProcessFile(file);
                    setSearchTerm('');
                  }}
                >
                  {file}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Selected Files */}
        <div className="flex flex-wrap gap-2 mb-4">
          {selectedProcessFiles.map((file, index) => (
            <div
              key={index}
              className="bg-gray-700 text-white px-2 py-1 rounded-lg flex items-center gap-2"
            >
              <span>{file}</span>
              <button
                onClick={() => removeProcessFile(file)}
                className="text-gray-400 hover:text-white"
              >
                ×
              </button>
            </div>
          ))}
        </div>

        {/* Process Description */}
        <div 
          className={`bg-gray-900 rounded-lg p-4 mb-4 ${isEditing ? 'border border-blue-500' : ''}`}
          onClick={() => setIsEditing(true)}
        >
          {isEditing ? (
            <textarea
              className="w-full h-full bg-transparent text-white resize-none focus:outline-none text-base font-normal min-h-[80px]"
              value={processDescription}
              onChange={(e) => setProcessDescription(e.target.value)}
              onBlur={() => setIsEditing(false)}
              autoFocus
              placeholder="Describe the process you want to analyze..."
            />
          ) : (
            <div className="text-white text-base font-normal cursor-text h-full">
              {processDescription || "Click here to describe the process..."}
            </div>
          )}
        </div>

        {/* Start Analysis Button */}
        <button
          className={`font-bold py-2 px-4 rounded-lg transition-colors ${
            currentStatus && currentStatus !== 'done'
              ? 'bg-gray-500 cursor-not-allowed'
              : 'bg-blue-500 hover:bg-blue-600'
          }`}
          onClick={() => {
            if (processDescription) {
              sendMessage({
                feature_request: processDescription,
                process_files: selectedProcessFiles,
                namespace: selectedNamespace
              });
              setIsEditing(false);
            }
          }}
          disabled={currentStatus && currentStatus !== 'done'}
        >
          {currentStatus && currentStatus !== 'done' ? 'Analysis in Progress...' : 'Start Process Analysis'}
        </button>
      </div>
    </div>
  );
};

export default ProcessConfig; 