import React, { createContext, useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from '../../../context/AppContext';

export const ProcessMapContext = createContext(null);

export const ProcessMapProvider = ({ children }) => {
  const { selectedNamespace } = useContext(AppContext);
  const [processFiles, setProcessFiles] = useState([]);
  const [fileSearchResults, setFileSearchResults] = useState([]);
  const [selectedProcessFiles, setSelectedProcessFiles] = useState([]);
  const [processDescription, setProcessDescription] = useState('');

  const searchFiles = useCallback(async (searchTerm) => {
    console.log('Searching for:', searchTerm, 'in namespace:', selectedNamespace);

    if (!searchTerm || searchTerm.length < 2) {
      setFileSearchResults([]);
      return;
    }

    // Mock data for testing
    const mockData = [
      'ACCT01.cbl',
      'ACCT02.cbl',
      'ACCT03.cbl',
      'CARD01.cbl',
      'CARD02.cbl',
      'ACCTMAIN.cbl',
      'CARDPROC.cbl'
    ];

    // Filter mock data based on search term
    const results = mockData.filter(file => 
      file.toLowerCase().includes(searchTerm.toLowerCase())
    );

    console.log('Search results:', results);
    setFileSearchResults(results);
  }, [selectedNamespace]);

  const addProcessFile = useCallback((file) => {
    setSelectedProcessFiles(prev => {
      if (!prev.includes(file)) {
        return [...prev, file];
      }
      return prev;
    });
    setFileSearchResults([]); // Clear search results after selection
  }, []);

  const removeProcessFile = useCallback((file) => {
    setSelectedProcessFiles(prev => prev.filter(f => f !== file));
  }, []);

  const value = {
    processFiles,
    setProcessFiles,
    fileSearchResults,
    searchFiles,
    selectedProcessFiles,
    addProcessFile,
    removeProcessFile,
    processDescription,
    setProcessDescription,
  };

  return (
    <ProcessMapContext.Provider value={value}>
      {children}
    </ProcessMapContext.Provider>
  );
};

ProcessMapProvider.propTypes = {
  children: PropTypes.node.isRequired,
}; 