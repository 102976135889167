import React, { useState, useMemo } from 'react';
import { ChevronDown, ChevronRight, CheckCircle, Circle, AlertCircle, Loader2 } from 'lucide-react';
import { ProcessingStatus } from '../../context/AppContext';
import PropTypes from 'prop-types';

const StatusStep = ({ label, status, textStatus }) => {
  const getStatusStyles = () => {
    switch (status) {
      case 'completed':
        return 'text-green-400';
      case 'in-progress':
        return 'text-yellow-400';
      case 'error':
        return 'text-red-400';
      default:
        return 'text-gray-500';
    }
  };

  const getStatusIcon = () => {
    switch (status) {
      case 'completed':
        return <CheckCircle size={16} />;
      case 'in-progress':
        return <Loader2 size={16} className="animate-spin" />;
      case 'error':
        return <AlertCircle size={16} />;
      default:
        return <Circle size={16} />;
    }
  };

  return (
    <div className={`flex flex-col gap-1 ${getStatusStyles()}`}>
      <div className="flex items-center gap-2">
        {getStatusIcon()}
        <span>{label}</span>
      </div>
      {textStatus && status === 'in-progress' && (
        <div className="ml-6 text-sm text-gray-400">{textStatus}</div>
      )}
    </div>
  );
};

StatusStep.propTypes = {
  label: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['completed', 'in-progress', 'error', 'pending']).isRequired,
  textStatus: PropTypes.string
};

const StatusBox = ({ currentStatus, textStatus }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const updatedSteps = useMemo(() => {
    const steps = [
      { 
        id: 'waiting',
        label: 'Waiting for feature request',
        status: 'pending'
      },
      {
        id: ProcessingStatus.START_FEATURE_ENRICHMENT,
        label: 'Enriching feature request',
        status: 'pending'
      },
      {
        id: ProcessingStatus.START_RELEVANT_FILES,
        label: 'Looking for relevant files',
        status: 'pending'
      },
      {
        id: ProcessingStatus.START_SOLUTION_PLAN,
        label: 'Creating solution plan',
        status: 'pending'
      },
      {
        id: ProcessingStatus.START_SUGGEST_EDITS,
        label: 'Defining edits per file',
        status: 'pending'
      },
      {
        id: ProcessingStatus.START_DEPENDENCY_IMPACT,
        label: 'Finding impacted dependencies',
        status: 'pending'
      },
      {
        id: ProcessingStatus.START_MINI_GRAPH,
        label: 'Building dependency graph',
        status: 'pending'
      }
    ];

    if (!currentStatus) return steps;
    
    const stepOrder = [
      'waiting',
      ProcessingStatus.START_FEATURE_ENRICHMENT,
      ProcessingStatus.START_RELEVANT_FILES,
      ProcessingStatus.START_SOLUTION_PLAN,
      ProcessingStatus.START_SUGGEST_EDITS, 
      ProcessingStatus.START_DEPENDENCY_IMPACT,
      ProcessingStatus.START_MINI_GRAPH,
      ProcessingStatus.DONE
    ];

    // Extract the base status without any "WAITING" or "ERROR" suffix
    const baseStatus = currentStatus.replace(/_(WAITING|ERROR)$/, '');
    const currentIndex = stepOrder.indexOf(baseStatus);
    
    return steps.map((step) => {
      const stepIndex = stepOrder.indexOf(step.id);
      
      // Keep previous steps completed
      if (stepIndex < currentIndex) {
        return { ...step, status: 'completed' };
      }
      // Current step is in-progress if it matches exactly, otherwise keep completed
      if (stepIndex === currentIndex) {
        // If we're in a WAITING state, mark the previous step as completed
        if (currentStatus.endsWith('_WAITING')) {
          return { ...step, status: 'completed' };
        }
        return { ...step, status: 'in-progress' };
      }
      return { ...step, status: 'pending' };
    });
  }, [currentStatus]);

  const currentStep = currentStatus === ProcessingStatus.DONE ?
    { ...updatedSteps[updatedSteps.length - 1], status: 'completed' } :
    updatedSteps.find(step => step.status === 'in-progress') || 
    updatedSteps.find(step => step.status === 'completed') || 
    updatedSteps[0];

  return (
    <div className="w-full bg-gray-800 border border-gray-700 rounded-lg h-auto">
      <div className="p-3 cursor-pointer flex items-center gap-2 w-full h-full" onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
        <StatusStep 
          label={currentStep.label}
          status={currentStep.status}
          textStatus={currentStep.status === 'in-progress' ? textStatus : null}
        />
      </div>

      {isExpanded && (
        <div className="px-2 pb-2 border-t border-gray-700">
          {updatedSteps.map((step) => (
            <div key={step.id} className="mt-1">
              <StatusStep 
                label={step.label}
                status={step.status}
                textStatus={step.status === 'in-progress' ? textStatus : null}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

StatusBox.propTypes = {
  currentStatus: PropTypes.string,
  textStatus: PropTypes.string
};

export default StatusBox; 