import React, { useState, useContext } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import Explorer from "../../components/Explorer/Explorer";
import AskAnalysis from "./FeatureRequest/ask_analysis";
import Dashboard from "../../components/Dashboard/Dashboard";
import { AppContext } from "../../context/AppContext";
import { useLocation } from "react-router-dom";
import StatusBox from "../../components/StatusBox/StatusBox";
import DataModelViewer from "../../components/DataModelViewer/DataModelViewer";
import { LogOut } from "lucide-react";
import { logout } from "../../services/auth";
import RequestedFeature from "./FeatureRequest/RequestedFeature";
import { Code } from "lucide-react";

const DEFAULT_FEATURE_TEXT =
  "Reduce waiting time in SDD payments to 3 working days\n" +
  "A customer Direct monthly deposit set to the 7th of the month. In December, that's a Saturday, so we have the bank collect on the next Monday. Then there are 5 waiting days (because there could be weekends or holidays, in theory), causing the money to land on Friday afternoon. Our platform client trades in the morning (with good reason), so the money is invested only on the next next Monday!\n" +
  "We should be able to significantly reduce this impact by allowing the waiting days to be specified in workdays (excluding SDD bank holidays). This allows platform to use the exact requirement: 3 workdays' worth of waiting days.";

const Analysis = () => {
  const {
    featureRequestText,
    setFeatureRequestText,
    currentStatus,
    textStatus,
  } = useContext(AppContext);
  const [isEditing, setIsEditing] = useState(false);
  const location = useLocation();
  const [showDataModel, setShowDataModel] = useState(false);

  const handleAnalysisTextChange = (newText) => {
    setFeatureRequestText(newText);
  };

  // Create featureRequestProps object
  const featureRequestProps = {
    analysisText: featureRequestText || DEFAULT_FEATURE_TEXT,
    isEditing,
    setIsEditing,
    onAnalysisTextChange: handleAnalysisTextChange,
  };

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col">
      {/* Top-level header */}
      <div className="bg-gray-800 p-4 flex justify-between items-center border-b border-gray-700">
        <h1 className="text-xl font-bold text-white">Tweezr</h1>
        <div className="flex items-center gap-8">
          <div className="flex items-center gap-2">
            <button
              onClick={() => {
                setShowDataModel(!showDataModel);
                if (!showDataModel) {
                  setTimeout(() => {
                    window.scrollTo({
                      top: document.documentElement.scrollHeight,
                      behavior: "smooth",
                    });
                  }, 100);
                }
              }}
              className={`relative inline-flex h-[24px] w-[40px] items-center rounded-[4px] transition-colors ${
                showDataModel ? "bg-green-500" : "bg-gray-700"
              }`}
            >
              <span
                className={`inline-flex items-center justify-center h-[20px] w-[22px] my-2 transform rounded-lg bg-white transition-transform ${
                  showDataModel
                    ? "translate-x-[1.15rem]"
                    : "translate-x-[0.15rem]"
                }`}
              >
                <Code size={14} className="text-gray-800" />
              </span>
            </button>
          </div>
          <button
            onClick={logout}
            className="flex items-center gap-3 px-6 py-2.5 text-white bg-gray-700/50 hover:bg-gray-600/50 rounded-xl transition-colors font-mono"
          >
            <LogOut className="w-5 h-5" />
            <span>Logout</span>
          </button>
        </div>
      </div>

      {/* Main content area - no padding on bottom */}
      <div className="flex-1 p-4 pb-0 flex flex-col">
        {/* Main Grid Layout */}
        <div className="flex-1 grid grid-cols-1 gap-4">
          <PanelGroup direction="horizontal" className="h-full" id="root">
            {/* Left Panel */}
            <Panel
              id="left"
              defaultSize={20}
              minSize={15}
              className="flex flex-col"
            >
              <PanelGroup
                direction="vertical"
                id="left-group"
                autoSaveId="left-group"
                className="flex flex-col items-start"
              >
                <Panel
                  id="feature-request"
                  defaultSize={70}
                  collapsible
                  className="w-full h-auto"
                  style={{ flex: "0 0 auto" }}
                >
                  <div className="bg-gray-800 rounded-lg border border-gray-700 overflow-hidden">
                    <RequestedFeature {...featureRequestProps} />
                  </div>
                </Panel>

                <PanelResizeHandle className="h-1.5 bg-gray-900 hover:bg-blue-500 transition-colors w-full" />

                <Panel id="dashboard" defaultSize={30} className="w-full" style={{ flex: "0 0 auto" }}>
                  <div className="bg-gray-800 rounded-lg border border-gray-700 overflow-hidden">
                    <Dashboard />
                  </div>
                </Panel>
              </PanelGroup>
            </Panel>

            <PanelResizeHandle className="w-1.5 bg-gray-900 hover:bg-blue-500 transition-colors" />

            {/* Middle Panel */}
            <Panel id="middle" defaultSize={50} minSize={30}>
              <div className="h-full flex flex-col">
                <div className="flex-1 bg-gray-800 rounded-lg border border-gray-700 overflow-auto mb-1.5">
                  <AskAnalysis />
                </div>
                <div className="flex-none">
                  <StatusBox
                    currentStatus={currentStatus}
                    textStatus={textStatus}
                  />
                </div>
              </div>
            </Panel>

            <PanelResizeHandle className="w-1.5 bg-gray-900 hover:bg-blue-500 transition-colors" />

            {/* Right Panel */}
            <Panel
              id="right"
              defaultSize={30}
              minSize={20}
              className="flex flex-col"
            >
              <PanelGroup direction="vertical" id="right-group">
                <Panel id="explorer" defaultSize={50} minSize={20}>
                  <div className="h-full bg-gray-800 rounded-lg border border-gray-700 overflow-hidden">
                    <Explorer
                      selectedFiles={location.state?.selectedFiles || []}
                    />
                  </div>
                </Panel>

                <PanelResizeHandle className="h-1.5 bg-gray-900 hover:bg-blue-500 transition-colors" />

                {/* Graph display temporarily disabled
                <Panel id="graph" defaultSize={50} minSize={20}>
                  <div className="h-full bg-gray-800 rounded-lg border border-gray-700 overflow-hidden">
                    <GraphDisplay showLegend={true} />
                  </div>
                </Panel>
                */}
              </PanelGroup>
            </Panel>
          </PanelGroup>
        </div>
      </div>

      {/* Data Model Section - Always visible at bottom */}
      <div className="p-4 bg-gray-900">
        {showDataModel && (
          <div className="bg-gray-800 rounded-lg border border-gray-700 overflow-hidden">
            <div className="max-h-[70vh] overflow-auto">
              <DataModelViewer />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Analysis;
