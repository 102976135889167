import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { ChevronRight, ChevronDown } from "lucide-react";
import { AppContext } from "../../context/AppContext";
import dataModelService from "../../services/dataModelService";
import Tooltip from "./Tooltip";
import { getFileIcon } from "../../shared/fileIcons";

// Add FileTreeNode PropTypes
const FileTreeNodePropTypes = {
  node: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
    path: PropTypes.string,
    children: PropTypes.array,
    description: PropTypes.string,
    relevancy: PropTypes.string,
  }).isRequired,
  level: PropTypes.number,
  path: PropTypes.string,
};

const Explorer = () => {
  const { relevantFilesContent, analysisContent, dependencyImpactContent } =
    useContext(AppContext);

  // Get relevant file paths based on analysis stage
  const getRelevantPaths = () => {
    const paths = new Set();
    // Add files from suggested changes
    if (analysisContent?.changes) {
      analysisContent.changes.forEach((change) => {
        paths.add(change.file_path);
      });
    }

    // Add dependency files only if we have dependency impact data
    if (dependencyImpactContent?.dependency_impacts) {
      dependencyImpactContent.dependency_impacts.forEach((impact) => {
        if (impact.key_change_file_path) paths.add(impact.key_change_file_path);
        if (impact.file_path) paths.add(impact.file_path);
        if (impact.code_changes) {
          Object.keys(impact.code_changes).forEach((path) => paths.add(path));
        }
      });
    }

    return paths;
  };

  // Get files based on analysis stage (removed toggle logic)
  const files_array = (() => {
    // Get all files from data model
    const allFiles = Object.entries(dataModelService.fileDataModel).map(
      ([path]) => {
        const fileData = relevantFilesContent.find(
          (file) => file.file_path === path
        );
        return {
          file_path: path,
          ...fileData,
        };
      }
    );

    const relevantPaths = getRelevantPaths();
    return allFiles.filter((file) => relevantPaths.has(file.file_path));
  })();

  // Updated helper function to check if a folder should be collapsed
  const shouldCollapseFolder = (node) => {
    // 1. Must be a folder
    if (node.type !== "folder") return false;

    // 2. Must have exactly one child
    if (!node.children || node.children.length !== 1) return false;

    // 3. That child must be a folder
    const childNode = node.children[0];
    return childNode.type === "folder";
  };

  // Helper function to get the collapsed path of nested folders
  const getCollapsedPath = (node) => {
    let path = [node.name];
    let current = node;

    // Keep collapsing until we reach a folder that shouldn't be collapsed
    while (current.children && shouldCollapseFolder(current)) {
      current = current.children[0];
      path.push(current.name);
    }

    return {
      displayPath: path.join("/"),
      finalNode: {
        ...current,
        name: path.join("/"),
        isCollapsed: true,
      },
    };
  };

  // Updated buildFileTree function
  const buildFileTree = (files) => {
    const root = { type: "root", children: {} };

    files.forEach((file) => {
      const pathParts = file.file_path.split("/");
      let current = root.children;

      pathParts.forEach((part, index) => {
        if (index === pathParts.length - 1) {
          // Create file node
          current[part] = {
            name: part,
            type: "file",
            path: file.file_path,
            relevancy: file.relevancy,
            description: file.textual_representation,
          };
        } else {
          // Create folder node
          if (!current[part]) {
            current[part] = {
              name: part,
              type: "folder",
              children: {},
              hasFiles: false,
            };
          }
          current = current[part].children;
        }
      });
    });

    // Mark folders that contain files directly
    const markDirectFiles = (node) => {
      if (node.type === "folder") {
        node.hasDirectFiles = node.children?.some(
          (child) => child.type === "file"
        );
        node.children?.forEach(markDirectFiles);
      }
      return node;
    };

    const convertToArray = (node) => {
      if (node.type === "folder" || node.type === "root") {
        node.children = Object.values(node.children)
          .map((child) => convertToArray(child))
          .sort((a, b) => {
            if (a.type !== b.type) return a.type === "folder" ? -1 : 1;
            return a.name.localeCompare(b.name);
          });
      }
      return node;
    };

    const tree = convertToArray(root);
    return markDirectFiles(tree);
  };

  // Update the getRelevancyClass function
  const getRelevancyClass = (node) => {
    if (node.type === "file" && node.relevancy?.toLowerCase() === "likely") {
      return "bg-green-600/20 rounded px-1"; // Adjusted green background with better opacity
    }
    return "";
  };

  // Updated FileTreeNode component
  const FileTreeNode = ({ node, level = 0, path = "" }) => {
    // Initialize expansion state based on whether it's a folder that shouldn't be collapsed
    const [isExpanded, setIsExpanded] = useState(
      node.type === "folder" && !shouldCollapseFolder(node)
    );

    // Skip root node rendering
    if (node.type === "root") {
      return (
        <div>
          {node.children?.map((childNode, index) => (
            <FileTreeNode
              key={index}
              node={childNode}
              level={level}
              path={path}
            />
          ))}
        </div>
      );
    }

    // Handle collapsed folders
    if (shouldCollapseFolder(node)) {
      const { displayPath, finalNode } = getCollapsedPath(node);
      return (
        <FileTreeNode
          node={{
            ...finalNode,
            name: displayPath,
            originalPath: path + "/" + displayPath,
          }}
          level={level}
          path={path}
        />
      );
    }

    const currentPath = node.path || `${path}/${node.name}`.replace(/^\//, "");
    const hasChildren = node.type === "folder" && node.children?.length > 0;

    const handleNodeClick = () => {
      if (node.type === "file") {
        // Find the corresponding file card and the ask analysis container
        const fileCard = document.querySelector(
          `[data-file-path="${node.path}"]`
        );
        const analysisContainer = document.querySelector(".analysis-container");

        if (fileCard && analysisContainer) {
          // Get the container's top position
          const containerTop = analysisContainer.getBoundingClientRect().top;

          // Calculate scroll position with padding (same as mb-4 = 1rem = 16px)
          const scrollPosition = fileCard.offsetTop - containerTop - 16;

          // Scroll the container
          analysisContainer.scrollTo({
            top: scrollPosition,
            behavior: "smooth",
          });
        }
      }

      if (hasChildren) {
        setIsExpanded(!isExpanded);
      }
    };

    const nodeContent = (
      <div
        className="flex items-center cursor-pointer hover:bg-gray-800 py-1 px-2 rounded group"
        onClick={handleNodeClick}
        style={{
          paddingLeft: `${level * 16 + (node.type === "file" ? 16 : 0)}px`,
        }}
        data-path={currentPath}
        data-type={node.type}
      >
        <div className="flex items-center gap-1 min-w-0 w-full">
          {hasChildren && (
            <span className="text-gray-500 flex-shrink-0">
              {isExpanded ? (
                <ChevronDown size={14} />
              ) : (
                <ChevronRight size={14} />
              )}
            </span>
          )}
          <div
            className={`flex items-center gap-1.5 min-w-0 ${getRelevancyClass(
              node
            )}`}
          >
            <span className="flex-shrink-0">{getFileIcon(node)}</span>
            <span
              className={`text-sm ${
                node.type === "folder" ? "font-medium" : ""
              } truncate break-all`}
            >
              {node.name}
            </span>
          </div>
        </div>
      </div>
    );

    return (
      <div className="mb-1">
        {node.type === "file" ? (
          <Tooltip
            content={node.description || "No description available"}
            title={
              node.relevancy?.toLowerCase() === "likely"
                ? "Likely Relevant File"
                : null
            }
          >
            {nodeContent}
          </Tooltip>
        ) : (
          nodeContent
        )}

        {isExpanded && node.children && (
          <div>
            {node.children.map((childNode, index) => (
              <FileTreeNode
                key={index}
                node={childNode}
                level={level + 1}
                path={currentPath}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  FileTreeNode.propTypes = FileTreeNodePropTypes;
  return (
    <div className="h-full flex flex-col">
      <div className="bg-gray-800/50 border-b border-gray-700">
        <div className="p-6">
          <h2 className="text-xl font-bold text-white">
            Explorer
          </h2>
        </div>
      </div>
      <div className="flex-1 overflow-auto p-4">
        <FileTreeNode node={buildFileTree(files_array)} />
      </div>
    </div>
  );
};

Explorer.propTypes = {
  // Remove unused prop types
};

export default Explorer;
