import React, { useState, useEffect, useContext, useMemo } from 'react';
import { AppContext } from '../../../context/AppContext';
import { CodeCacheContext } from '../../../context/CodeCacheContext';
import './CodeRenderer.css';
import PropTypes from 'prop-types';
import { getFileIcon } from '../../../shared/fileIcons';

const CodeRenderer = ({ change, filePath, showHeader = true }) => {
  const [fullCode, setFullCode] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { getCode } = useContext(AppContext);
  const { getCachedCode, setCachedCode } = useContext(CodeCacheContext);
  const [expandedSections, setExpandedSections] = useState(new Set());
  const CONTEXT_SIZE = 3;

  const ExpandButton = ({ onClick, direction, numLines }) => (
    <tr className="h-6 hover:bg-gray-800/50 border-y border-gray-700/50">
      <td colSpan="3" className="text-center">
        <button
          onClick={onClick}
          className="inline-flex items-center gap-2 text-blue-400 hover:text-blue-300 text-xs py-1"
        >
          <span className="text-gray-500">
            {direction === 'up' ? '↑' : '↓'}
          </span>
          <span>
            {direction === 'up' ? `Show ${numLines} lines above` : `Show ${numLines} lines below`}
          </span>
        </button>
      </td>
    </tr>
  );

  ExpandButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    direction: PropTypes.oneOf(['up', 'down']).isRequired,
    numLines: PropTypes.number.isRequired
  };

  const processedChanges = useMemo(() => {
    if (!change?.code_changes || !fullCode) return [];
    
    const lines = [];
    const codeChanges = Array.isArray(change.code_changes) ? change.code_changes : [change.code_changes];
    const allLines = fullCode.split('\n');
    
    codeChanges.forEach((codeChange, changeIndex) => {
      const nextChange = codeChanges[changeIndex + 1];
      const prevChange = codeChanges[changeIndex - 1];
      
      // Calculate start and end lines considering adjacent changes
      const contextStart = Math.max(
        prevChange ? prevChange.end_line + 1 : 0,
        codeChange.start_line - CONTEXT_SIZE
      );
      
      const contextEnd = Math.min(
        nextChange ? nextChange.start_line - 1 : allLines.length - 1,
        codeChange.end_line + CONTEXT_SIZE
      );

      // Calculate remaining lines between changes
      const remainingLinesAbove = prevChange ? 
        contextStart - (prevChange.end_line + 1) : 
        contextStart;
        
      const remainingLinesBelow = nextChange ? 
        nextChange.start_line - (contextEnd + 1) : 
        allLines.length - (contextEnd + 1);

      // If only 1 line above, show it instead of expand button
      if (remainingLinesAbove === 1) {
        const lineNumber = contextStart;
        lines.push({
          type: 'context',
          lineNumber: lineNumber + 1,
          oldLineNumber: lineNumber + 1,
          content: allLines[lineNumber]
        });
      } else if (remainingLinesAbove > 0 && 
          !expandedSections.has(`${changeIndex}-up`) &&
          !expandedSections.has(`${changeIndex - 1}-down`)) {
        lines.push({
          type: 'expand-up',
          changeIndex,
          numLines: remainingLinesAbove
        });
      }

      // Add context and changed lines
      const expandedStart = expandedSections.has(`${changeIndex}-up`) ? 
        (prevChange ? prevChange.end_line + 1 : 0) : 
        contextStart;

      // Add lines before change
      for (let i = expandedStart; i < codeChange.start_line; i++) {
        lines.push({
          type: 'context',
          lineNumber: i + 1,
          oldLineNumber: i + 1,
          content: allLines[i]
        });
      }

      const oldLines = codeChange.old_code?.split('\n') || [];
      const newLines = codeChange.new_code?.split('\n') || [];
      const maxLines = Math.max(oldLines.length, newLines.length);

      for (let i = 0; i < maxLines; i++) {
        const oldLine = oldLines[i];
        const newLine = newLines[i];

        if (oldLine && newLine) {
          if (oldLine !== newLine) {
            lines.push({
              type: 'deletion',
              lineNumber: null,
              oldLineNumber: codeChange.start_line + i,
              content: oldLine
            });
            lines.push({
              type: 'addition',
              lineNumber: codeChange.start_line + i,
              oldLineNumber: null,
              content: newLine
            });
          } else {
            lines.push({
              type: 'context',
              lineNumber: codeChange.start_line + i,
              oldLineNumber: codeChange.start_line + i,
              content: oldLine
            });
          }
        } else if (oldLine) {
          lines.push({
            type: 'deletion',
            lineNumber: null,
            oldLineNumber: codeChange.start_line + i,
            content: oldLine
          });
        } else if (newLine) {
          lines.push({
            type: 'addition',
            lineNumber: codeChange.start_line + i,
            oldLineNumber: null,
            content: newLine
          });
        }
      }

      // Add lines after change
      const expandedEnd = expandedSections.has(`${changeIndex}-down`) ? 
        (nextChange ? nextChange.start_line - 1 : allLines.length - 1) : 
        contextEnd;

      for (let i = codeChange.end_line + 1; i <= expandedEnd; i++) {
        if (i < allLines.length) {
          lines.push({
            type: 'context',
            lineNumber: i + 1,
            oldLineNumber: i + 1,
            content: allLines[i]
          });
        }
      }

      // If only 1 line below, show it instead of expand button
      if (remainingLinesBelow === 1) {
        const lineNumber = contextEnd + 1;
        if (lineNumber < allLines.length) {
          lines.push({
            type: 'context',
            lineNumber: lineNumber + 1,
            oldLineNumber: lineNumber + 1,
            content: allLines[lineNumber]
          });
        }
      } else if (remainingLinesBelow > 0 && 
          !expandedSections.has(`${changeIndex}-down`) &&
          !expandedSections.has(`${changeIndex + 1}-up`)) {
        lines.push({
          type: 'expand-down',
          changeIndex,
          numLines: remainingLinesBelow
        });
      }
    });

    return lines;
  }, [change, fullCode, expandedSections]);

  const handleExpand = (changeIndex, direction) => {
    setExpandedSections(prev => {
      const next = new Set(prev);
      next.add(`${changeIndex}-${direction}`);
      
      // If expanding down, also mark the "up" section of next change as expanded
      if (direction === 'down') {
        next.add(`${changeIndex + 1}-up`);
      }
      // If expanding up, also mark the "down" section of previous change as expanded
      if (direction === 'up') {
        next.add(`${changeIndex - 1}-down`);
      }
      
      return next;
    });
  };

  useEffect(() => {
    const fetchCode = async () => {
      setIsLoading(true);
      try {
        const cleanPath = filePath.replace(/^\/+/, '');
        const cachedContent = getCachedCode(cleanPath);
        
        if (cachedContent) {
          setFullCode(cachedContent);
        } else {
          const codeContent = await getCode(cleanPath);
          if (codeContent) {
            setCachedCode(cleanPath, codeContent);
            setFullCode(codeContent);
          }
        }
      } catch (error) {
        console.error('Error fetching code:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (filePath) {
      fetchCode();
    }
  }, [filePath, getCode, getCachedCode, setCachedCode]);

  if (isLoading) {
    return <div className="text-gray-400 p-4">Loading code...</div>;
  }

  return (
    <div className="bg-[#0D1117] rounded-lg overflow-hidden border border-gray-700">
      {/* File header - only show if showHeader is true */}
      {showHeader && (
        <div className="flex items-center px-4 py-2 bg-[#161B22] border-b border-gray-700">
          <div className="flex items-center gap-2">
            {getFileIcon(filePath)}
            <span className="text-gray-400 font-mono text-sm">{filePath}</span>
          </div>
        </div>
      )}

      {/* Code changes */}
      <div className="overflow-x-auto">
        <table className="w-full border-spacing-0">
          <tbody>
            {processedChanges.map((line, idx) => 
              line.type === 'expand-up' ? (
                <ExpandButton
                  key={`expand-up-${idx}`}
                  direction="up"
                  numLines={line.numLines}
                  onClick={() => handleExpand(line.changeIndex, 'up')}
                />
              ) : line.type === 'expand-down' ? (
                <ExpandButton
                  key={`expand-down-${idx}`}
                  direction="down"
                  numLines={line.numLines}
                  onClick={() => handleExpand(line.changeIndex, 'down')}
                />
              ) : (
                <tr 
                  key={idx} 
                  className={`
                    hover:bg-gray-800/50
                    ${line.type === 'addition' ? 'bg-green-500/10' : ''}
                    ${line.type === 'deletion' ? 'bg-red-500/10' : ''}
                  `}
                >
                  {/* Old line number */}
                  <td className="select-none pl-4 pr-2 py-0.5 text-right text-gray-500 border-r border-gray-700 w-12 font-mono text-xs">
                    {line.oldLineNumber}
                  </td>
                  
                  {/* Change indicator and new line number */}
                  <td className="select-none pl-2 pr-2 py-0.5 text-right text-gray-500 border-r border-gray-700 w-12 font-mono text-xs">
                    {line.type === 'addition' && <span className="text-white mr-1">+</span>}
                    {line.type === 'deletion' && <span className="text-white mr-1">-</span>}
                    {line.lineNumber}
                  </td>
                  
                  {/* Code content */}
                  <td className={`pl-4 pr-4 py-0.5 whitespace-pre font-mono text-sm ${
                    line.type === 'addition' ? 'text-green-300' :
                    line.type === 'deletion' ? 'text-red-300' :
                    'text-gray-300'
                  }`}>
                    {line.content}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

CodeRenderer.propTypes = {
  change: PropTypes.shape({
    change_description: PropTypes.string,
    code_changes: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]),
    highlighted_code: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
    ])
  }),
  filePath: PropTypes.string.isRequired,
  showHeader: PropTypes.bool
};

export default CodeRenderer;
