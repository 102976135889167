import React, { createContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

export const CodeCacheContext = createContext(null);

export const CodeCacheProvider = ({ children }) => {
  const [codeCache, setCodeCache] = useState({});

  const getCachedCode = useCallback((filePath) => {
    return codeCache[filePath];
  }, [codeCache]);

  const setCachedCode = useCallback((filePath, code) => {
    setCodeCache(prev => ({
      ...prev,
      [filePath]: code
    }));
  }, []);

  return (
    <CodeCacheContext.Provider value={{ getCachedCode, setCachedCode }}>
      {children}
    </CodeCacheContext.Provider>
  );
};

CodeCacheProvider.propTypes = {
  children: PropTypes.node.isRequired
};
