import React, { useState, useEffect } from 'react';
import TweezrLogo from '../../../components/ui/TweezrLogoGreen.png';

const loadingMessages = [
  "Making a plan...",
  "Checking relevant files...",
  "Analyzing dependencies...",
  "Mapping code changes...",
  "Evaluating impact...",
  "Building solution strategy...",
];

const LoadingAnimation = () => {
  const [currentMessage, setCurrentMessage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessage((prev) => (prev + 1) % loadingMessages.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="loading-container">
      <div className="loading-message-container">
        <div className="loading-logo">
          <img 
            src={TweezrLogo}
            alt="Tweezr Logo" 
            className="w-24 h-24 object-contain"
          />
        </div>
        
        <div className="relative h-8 mb-8">
          {loadingMessages.map((message, index) => (
            <div
              key={index}
              className={`absolute w-full text-center transition-all duration-500 transform ${
                index === currentMessage
                  ? 'opacity-100 translate-y-0'
                  : 'opacity-0 translate-y-4'
              }`}
            >
              <span className="loading-message">{message}</span>
            </div>
          ))}
        </div>

        <div className="loading-dots">
          <div className="loading-dot"></div>
          <div className="loading-dot animate-pulse delay-150"></div>
          <div className="loading-dot animate-pulse delay-300"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingAnimation; 