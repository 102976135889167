import React, { useState, useRef } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

const Tooltip = ({ children, content }) => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const updatePosition = () => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: rect.bottom + 8, // 8px gap
        left: rect.left,
      });
    }
  };

  const handleMouseEnter = () => {
    setIsVisible(true);
    updatePosition();
  };
  return (
    <div className="relative inline-block" ref={containerRef}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible &&
        createPortal(
          <div
            style={{
              position: "fixed",
              top: `${tooltipPosition.top}px`,
              left: `${tooltipPosition.left}px`,
            }}
            className={`z-[99999] p-4 
            bg-gray-900 backdrop-blur-md
            text-white text-sm 
            rounded-lg 
            w-max max-w-md
            border border-indigo-500/20
            transition-all duration-200 ease-out
            animate-fadeIn`}
          >
            <div className="text-blue-100 leading-relaxed">{content}</div>
            <div
              className={`absolute -top-1.5 left-1/3 -translate-x-1/2
              w-3 h-3 bg-gray-900
              border-t border-l border-gray-500/20
              transform rotate-45 backdrop-blur-md`}
            ></div>
          </div>,
          document.body
        )}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
};

export default Tooltip;
