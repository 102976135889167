export const login = async (username, password) => {
    try {
        const response = await fetch('/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ username, password }),
        });

        if (!response.ok) {
            throw new Error('Login failed');
        }

        const data = await response.json();
        console.log('Login response data:', data);
        
        if (data.token) {
            console.log('Storing token:', data.token);
            localStorage.setItem('auth_token', data.token);
        } else {
            console.warn('No token in login response');
        }
        
        return data;
    } catch (error) {
        console.error('Login error:', error);
        throw error;
    }
};

export const logout = () => {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('allowed_namespaces');
    window.location.href = '/login';
};

export const getToken = () => {
    return localStorage.getItem('auth_token');
};

export const isAuthenticated = () => {
    return !!getToken();
};

// Decode JWT token without verification
const decodeToken = (token) => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    } catch (error) {
        console.error('Error decoding token:', error);
        return null;
    }
};

// Get allowed namespaces from token
export const getAllowedNamespaces = () => {
    const token = getToken();
    if (!token) return [];
    
    const decoded = decodeToken(token);
    return decoded?.namespaces || [];
};

// Add token to all fetch requests
export const setupAuthInterceptor = () => {
    const originalFetch = window.fetch;
    window.fetch = async (...args) => {
        const [resource, config] = args;
        
        // Don't add auth header for login request
        if (resource.includes('/api/login')) {
            return originalFetch(resource, config);
        }

        const token = getToken();
        if (token) {
            const headers = {
                ...config?.headers,
                'Authorization': `Bearer ${token}`,
            };
            args[1] = { ...config, headers };
        }
        
        try {
            const response = await originalFetch(...args);
            if (response.status === 401) {
                logout();
            }
            return response;
        } catch (error) {
            console.error('Request error:', error);
            throw error;
        }
    };
};

// Check if token is expired
export const isTokenExpired = () => {
    const token = getToken();
    if (!token) return true;
    
    const decoded = decodeToken(token);
    if (!decoded || !decoded.exp) return true;
    
    return Date.now() >= decoded.exp * 1000; // exp is in seconds, Date.now() is in milliseconds
}; 